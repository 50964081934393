<template lang="pug">
    .lang-wrapper(:class="classMergerLS")
        span.floating-label(ref="langFlSpan" v-show="viewSelector == 'security'") {{ dafaultSpan }}
        .current-lang-container(:class="classMergerLS" @click="toggleOtherLang"
                                v-click-outside="outsideClick")
          .current__lang(:class="classMergerLS") {{ localeTitle }}
          .lang__chevron

        .triangle(v-show="toggleFlagImg" :class="classMergerLS")
        .other__lang(:class="classMergerLS")
            .lang-row(v-for="locale in getLangArray" :key="locale.iso"
                      :class="{ 'active': locale.iso == localeTitle.toLowerCase(), 'disabled': !locale.active, 'top-m': toggleFlagImg }"
                @click="changeLocale(locale.iso)")
               img.lang__logo(v-show="toggleFlagImg" :src="locale.logo")
               span.lang__title {{ $t('languages.'+locale.iso) }}
</template>

<script>
import LanguageSelector from './LanguageSelector.vue';

export default {
  extends: LanguageSelector
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/serviceComponents/language-selector_sw.scss";
</style>